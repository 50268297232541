import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; // Use the context to handle registration

const Register = () => {
  const navigate = useNavigate();
  const { register, error, loading } = useAuth(); // Get register function and state from AuthContext
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState(''); // Success message after registration

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }

    try {
      await register({ email, password }); // Call the register function from AuthContext

      setMessage('Registration successful! Redirecting to login...');
      setTimeout(() => {
        navigate('/login'); // Redirect to login after successful registration
      }, 3000); // Redirect after 3 seconds
    } catch (err) {
      // Error message will come from AuthContext automatically
      console.error('Registration error:', err);
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', padding: 2 }}>
      <Box sx={{ maxWidth: 400, width: '100%' }}>
        <Typography variant="h4" gutterBottom align="center">Register</Typography>

        {/* Display error or success message */}
        {error && <Typography color="error" variant="body2" align="center">{error}</Typography>}
        {message && <Typography color="success" variant="body2" align="center">{message}</Typography>}

        <form onSubmit={handleSubmit}>
          {/* Email field */}
          <TextField
            fullWidth
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            sx={{ mb: 2 }}
          />

          {/* Password field */}
          <TextField
            fullWidth
            type="password"
            placeholder="Password"
            value={password}  
            onChange={(e) => setPassword(e.target.value)}
            required
            sx={{ mb: 2 }}
          />

          {/* Confirm Password field */}
          <TextField
            fullWidth
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            sx={{ mb: 2 }}
          />

          {/* Submit button */}
          <Button
            variant="contained"
            fullWidth
            type="submit"
            disabled={loading}
          >
            {loading ? 'Registering...' : 'Register'}
          </Button>
        </form>

        {/* Link to Login page if already have an account */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Typography variant="body2">Already have an account?</Typography>
          <Button
            variant="text"
            color="primary"
            sx={{ marginLeft: 1 }}
            onClick={() => navigate('/login')}
          >
            Login
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Register;
