import React, { useState, useEffect } from 'react';  // Import useState and useEffect
import { Box, Typography } from '@mui/material';
import MediaChannelsXXX from '../components/Chat/MediaChannelsXXX'; // Import your UserChannels component

const UserChannelsPage = () => {
  const [selectedChannel, setSelectedChannel] = useState(null); // Track selected channel
  const [channels, setChannels] = useState([]);  // State for storing the channels
  const [loading, setLoading] = useState(true);  // State for loading indicator
  const [error, setError] = useState(null);  // State for error handling

  // Fetch channels from API when the component mounts
  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const response = await fetch('/api/storj/files');  // Replace with your actual API endpoint
        const data = await response.json();
        
        if (response.ok) {
          setChannels(data.files);  // Assuming the API returns a 'files' array
        } else {
          setError(data.message || 'Failed to fetch channels');
        }
      } catch (error) {
        setError('Error fetching channels: ' + error.message);
      } finally {
        setLoading(false);  // Set loading to false once the request is completed
      }
    };

    fetchChannels();
  }, []);  // Empty dependency array means this runs only once on component mount

  // Function to handle channel selection
  const handleChannelSelect = (channel) => {
    console.log('Channel selected:', channel);
    setSelectedChannel(channel);  // Update selected channel state
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>User Channels</Typography>

      {/* Display loading or error message */}
      {loading && <Typography variant="body1">Loading channels...</Typography>}
      {error && <Typography variant="body1" color="error">{error}</Typography>}

      {/* Display the UserChannels component */}
      <MediaChannelsXXX channels={channels} onChannelSelect={handleChannelSelect} /> {/* Pass channels and handleChannelSelect as props */}
      
      {/* Display selected channel details */}
      {selectedChannel && (
        <Box mt={2}>
          <Typography variant="h6">Selected Channel:</Typography>
          <Typography variant="body1">{selectedChannel.name}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default UserChannelsPage;
