import React from 'react';
import { Button, Box, Typography } from '@mui/material';

const MediaChannelsXXX = ({ channels, onChannelSelect }) => {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>Available Channels</Typography>
      {channels.length === 0 ? (
        <Typography variant="body1">No channels found</Typography>
      ) : (
        channels.map((channel) => (
          <Button
            key={channel.objectKey}  // Assuming 'objectKey' is a unique identifier for each channel
            onClick={() => onChannelSelect(channel)} // Call onChannelSelect when a channel is clicked
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginBottom: '10px' }}
          >
            {channel.objectKey}  {/* You can display more channel-specific info here */}
          </Button>
        ))
      )}
    </Box>
  );
};

export default MediaChannelsXXX;
