import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Navigation from './components/Navigation'; // Navigation component
import Dashboard from './components/Dashboard'; // Dashboard component
import Login from './components/Login'; // Login component
import Register from './components/Register'; // Register component
import UserChannelsPage from './pages/UserChannelsPage'; // UserChannelsPage component
import MediaLoaderPage from './pages/MediaLoaderPage'; // MediaLoaderPage component
import DynamicMediaPage from './pages/DynamicMediaPage';
import S3Page from './pages/S3Page';


import theme from './theme'; // Custom MUI theme

// Private Route for protected pages
const PrivateRoute = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) return <div>Loading...</div>; // Show loading state while user auth status is being determined

  if (!user) {
    return <Navigate to="/login" />; // Redirect to login if user is not authenticated
  }

  return children; // Render the children if authenticated
};

const App = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true); // Sidebar state

  const toggleSidebar = () => {
    setSidebarOpen((prevState) => !prevState); // Toggle sidebar state
  };

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Router>
          <Navigation sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
          
          <Routes>
            {/* Public Routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />

            {/* Private Routes */}
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
                </PrivateRoute>
              }
            />
            <Route
              path="/user-channels"
              element={
                <PrivateRoute>
                  <UserChannelsPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/media-loader"
              element={
                <PrivateRoute>
                  <MediaLoaderPage />
                </PrivateRoute>
              }
            />

            <Route
              path="/media"
              element={
                <PrivateRoute>
                  <DynamicMediaPage />
                </PrivateRoute>
              }
            />


            <Route
              path="/s3"
              element={
                <PrivateRoute>
                  <S3Page />
                </PrivateRoute>
              }
            />

            {/* Default Redirect */}
            <Route path="/" element={<Navigate to="/dashboard" />} />


          </Routes>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
