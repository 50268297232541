import React, { useEffect, useState, useRef } from 'react';
import { Box, CircularProgress, Typography, List, ListItem, ListItemText, useMediaQuery } from '@mui/material';
import { useAuth, useAxios } from '../../contexts/AuthContext';  // Use useAxios hook
import ChatSender from './ChatSender';
import ChatMessage from './ChatMessage';

const Chat = ({ channel, files }) => {
  const { user, token } = useAuth();  // Assuming token is now part of the `useAuth` hook
  const axiosInstance = useAxios();  // Get axiosInstance from the custom hook
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const isMobile = useMediaQuery('(max-width:600px)');
  const messagesEndRef = useRef(null); // Ref to scroll to the bottom of the messages

  const fetchMessages = async () => {
    if (!channel || !channel._id) return;

    setLoading(true);
    setError(null);

    try {
      const response = await axiosInstance.get(`/api/message/channel/${channel._id}/messages`);

      if (response.data.success) {
        setMessages(response.data.messages);
      } else {
        throw new Error('Failed to fetch messages');
      }
    } catch (error) {
      setError(error.message || 'An error occurred while fetching messages');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, [channel, token]);  // Ensure the effect re-runs when token or channel changes

  useEffect(() => {
    if (!loading && messages.length > 0) {
      scrollToBottom();
    }
  }, [loading, messages]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'auto' });
    }
  };

  if (loading) {
    return (
      <Box sx={{ textAlign: 'center' }}>
        <CircularProgress />
        <Typography>Loading messages...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ textAlign: 'center' }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: isMobile ? '78vh' : '80vh' }}>
      <Typography variant="h6" gutterBottom>
        Channel: {channel?.name || 'No Channel Selected'}
      </Typography>

      <Box sx={{ flex: 1, overflowY: 'auto', display: 'flex', flexDirection: 'column', width: '100%' }}>
        {messages.length > 0 ? (
          messages.map((message) => (
            <ChatMessage key={message._id} message={message} setMessages={setMessages} />
          ))
        ) : (
          <Typography>No messages available</Typography>
        )}

        <div ref={messagesEndRef} />
      </Box>

      {files && files.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6" gutterBottom>
            Files in this Channel:
          </Typography>
          <List>
            {files.map((file, index) => (
              <ListItem key={index}>
                <ListItemText primary={file.name} secondary={`Size: ${file.size} bytes`} />
              </ListItem>
            ))}
          </List>
        </Box>
      )}

      <Box sx={{ height: isMobile ? '10vh' : '8vh' }}>
        <ChatSender channel={channel} setMessages={setMessages} fetchMessages={fetchMessages} />
      </Box>
    </Box>
  );
};

export default Chat;
