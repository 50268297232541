import React, { useState } from 'react';
import { Box, Typography, TextField, Button, CircularProgress } from '@mui/material';

const MediaLoaderPage = () => {
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleImageUrlChange = (event) => {
    setImageUrl(event.target.value);
  };

  const loadImage = () => {
    setLoading(true);
    setError(null);

    // Validate URL format (this could be more sophisticated if needed)
    if (!imageUrl) {
      setError('Please provide a valid image URL.');
      setLoading(false);
      return;
    }

    const img = new Image();
    img.onload = () => {
      setLoading(false);
    };
    img.onerror = () => {
      setError('Error loading image. Please check the URL.');
      setLoading(false);
    };
    img.src = imageUrl; // Trigger the image load
  };

  return (
    <Box sx={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', bgcolor: 'background.default', p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Load and Display Image from URL
      </Typography>

      <TextField
        label="Image URL"
        variant="outlined"
        fullWidth
        value={imageUrl}
        onChange={handleImageUrlChange}
        sx={{ mb: 2 }}
      />

      <Button variant="contained" color="primary" onClick={loadImage} disabled={loading}>
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Load Image'}
      </Button>

      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}

      {imageUrl && !loading && !error && (
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', width: '100%', textAlign: 'center' }}>
          <img
            src={imageUrl}
            alt="Dynamic"
            style={{
              maxWidth: '100%',
              maxHeight: '80vh',
              objectFit: 'contain',
              borderRadius: '8px',
            }}
            onError={() => setError('Failed to load image')}
          />
        </Box>
      )}
    </Box>
  );
};

export default MediaLoaderPage;
