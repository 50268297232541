import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, CircularProgress } from '@mui/material';
import Cookies from 'js-cookie';  // Assuming you're using the js-cookie package for cookie management

const S3Page = () => {
    const [files, setFiles] = useState([]);
    const [error, setError] = useState(null);
    const [loadingFiles, setLoadingFiles] = useState(false);
    const [loadingUserData, setLoadingUserData] = useState(true);
    const [user, setUser] = useState(null);  // user state to hold the user data
    const [authLoading, setAuthLoading] = useState(true);  // Authentication loading state
    const [authError, setAuthError] = useState(null);  // Authentication error state

    // Simulating an authentication check and fetching user data
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                // Simulate fetching user data and authentication status
                const token = Cookies.get('accessToken') || localStorage.getItem('accessToken');
                if (!token) {
                    throw new Error('No token found');
                }

                // Example of an API call to fetch user data based on the token
                const response = await fetch('http://localhost:5000/api/users/me', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Authentication failed');
                }

                const data = await response.json();
                setUser(data);
                console.log('User data fetched:', data);
            } catch (error) {
                console.error('Error fetching user data:', error);
                setAuthError(error.message);
            } finally {
                setAuthLoading(false);
            }
        };

        fetchUserData();
    }, []);

    // Fetch files for the channel once user data is available and authentication is complete
    useEffect(() => {
        if (authLoading) {
            console.log('Auth loading in progress. Skipping fetch for files.');
            return;  // Skip the fetch if authentication is still loading
        }

        if (authError) {
            setError(`Authentication Error: ${authError}`);
            return;  // Skip file fetch if authentication failed
        }

        if (!user?.userId) {
            setError('User not authenticated or missing user ID.');
            return;  // Skip file fetch if no valid user
        }

        const fetchFiles = async () => {
            setLoadingFiles(true);
            try {
                console.log('Fetching files for channel:', user.channelId);  // Assuming channelId is available in user data
                const response = await fetch(`http://localhost:5000/api/storj/files/${user.channelId}`);
                if (!response.ok) {
                    throw new Error(`Failed to fetch files: ${response.status}`);
                }
                const data = await response.json();
                setFiles(data.files || []);
            } catch (error) {
                console.error('Error fetching files:', error);
                setError('Failed to fetch files.');
            } finally {
                setLoadingFiles(false);
            }
        };

        fetchFiles();
    }, [authLoading, authError, user]);

    return (
        <Container>
            <Box sx={{
                width: '100%',
                bgcolor: 'blue',
                color: 'white',
                p: 2,
                borderRadius: 2,
                mt: 15
            }}>
                <Typography variant="h6">User ID: {user?.userId || 'Loading...'}</Typography>
                <Typography variant="h6">Channel ID: {user?.channelId || 'Loading...'}</Typography>
                <Typography variant="h6">Creator ID: {user?.role || 'Loading...'}</Typography>
            </Box>

            <Typography variant="h4" gutterBottom>
                S3 File List for Channel {user?.channelId || 'Loading...'}
            </Typography>

            {authLoading || loadingFiles ? (
                <CircularProgress />
            ) : error ? (
                <Typography color="error">{error}</Typography>
            ) : (
                <table border="1" style={{ width: '100%', marginTop: '20px' }}>
                    <thead>
                        <tr>
                            <th>Bucket Name</th>
                            <th>Object Key</th>
                        </tr>
                    </thead>
                    <tbody>
                        {files.map((file, index) => (
                            <tr key={index}>
                                <td>{file.bucketName}</td>
                                <td>{file.objectKey}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </Container>
    );
};

export default S3Page;
