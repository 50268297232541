import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import axiosInstance from '../../API/axiosInstance';

const ChatMessage = ({ message, setMessages }) => {
    const { user } = useAuth();
    const [senderEmail, setSenderEmail] = useState('');
    const [openPreview, setOpenPreview] = useState(false);
    const [previewFile, setPreviewFile] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [touchStartX, setTouchStartX] = useState(0);  // For swipe gesture tracking

    useEffect(() => {
        setSenderEmail(message.email || user?.email || 'Unknown Email');
    }, [message, user]);

    // Handle opening the modal to preview the file
    const handleOpenPreview = (file, index) => {
        setPreviewFile(file);
        setCurrentImageIndex(index); // Set the index of the clicked image
        setOpenPreview(true);
    };

    // Handle closing the modal
    const handleClosePreview = () => {
        setOpenPreview(false);
        setPreviewFile(null);
    };

    // Slide to the next image
    const handleNextImage = () => {
        const nextIndex = (currentImageIndex + 1) % fileUrls.length;  // Loop back to the first image after the last one
        setCurrentImageIndex(nextIndex);
        setPreviewFile(fileUrls[nextIndex]); // Update preview file to the next image
    };

    // Slide to the previous image
    const handlePrevImage = () => {
        const prevIndex = (currentImageIndex - 1 + fileUrls.length) % fileUrls.length;  // Loop to the last image if on the first
        setCurrentImageIndex(prevIndex);
        setPreviewFile(fileUrls[prevIndex]); // Update preview file to the previous image
    };

    // Handle swipe gesture to detect left/right swipe
    const handleTouchStart = (e) => {
        const touchStart = e.touches[0].clientX;
        setTouchStartX(touchStart); // Store the start touch position
    };

    const handleTouchMove = (e) => {
        // Optional: Implement some logic to prevent swiping if needed
    };

    const handleTouchEnd = (e) => {
        const touchEnd = e.changedTouches[0].clientX;
        const touchDiff = touchStartX - touchEnd;

        // Swipe left (next image)
        if (touchDiff > 50) {
            handleNextImage();
        }
        // Swipe right (previous image)
        if (touchDiff < -50) {
            handlePrevImage();
        }
    };

    // Handle message deletion
    // Handle message deletion and file removal from Storj S3
const handleDeleteMessage = async () => {
    if (!window.confirm("Are you sure you want to delete this message and all associated files?")) return;

    try {
        const token = localStorage.getItem('accessToken');
        if (!token) throw new Error("Token not available.");

        // Step 1: Delete message from the server
        await axiosInstance.delete(`/api/message/channel/${message.channel_id}/message/${message.message_id}`, {
            headers: { Authorization: `Bearer ${token}` }
        });


        // Helper function to extract the file name from the URL and remove `?wrap=0`
// Helper function to extract the file name from the URL and remove `?wrap=0`
const getFileNameFromUrl = (fileUrl) => {
    // Remove any query parameters such as `?wrap=0`
    const urlWithoutParams = fileUrl.split('?')[0];
    const urlParts = urlWithoutParams.split('/');
    return urlParts[urlParts.length - 1];  // Return the last part of the URL (file name)
};

// Step 2: Delete files from Storj S3
if (fileUrls.length > 0) {
    await Promise.all(fileUrls.map(async (fileUrl) => {
        try {
            const fileName = getFileNameFromUrl(fileUrl); // Extract the file name from the URL without `?wrap=0`

            // Debug log the file name
            console.log(`Attempting to delete file: ${fileName}`);

            // Send delete request to the backend
            const response = await axiosInstance.delete(`/api/storage/delete/${fileName}`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            // Check response status
            if (response.status === 200) {
                console.log(`Successfully deleted file: ${fileName} from Storj`);
            } else {
                console.log(`Failed to delete file: ${fileName}. Status: ${response.status}`);
            }
        } catch (error) {
            console.error(`Error deleting file ${fileUrl} from Storj:`, error.response || error.message);
        }
    }));
}

        // Step 3: Remove the deleted message from local state
        setMessages((prevMessages) => prevMessages.filter((msg) => msg.message_id !== message.message_id));
    } catch (error) {
        console.error("Error deleting message:", error);
        alert("Failed to delete the message. Please try again.");
    }
};

// Helper function to extract the file name from URL (assuming URL structure allows for this)
const getFileNameFromUrl = (url) => {
    const urlParts = url.split('/');
    return urlParts[urlParts.length - 1]; // Get the last part of the URL, which is typically the file name
};


    // Safely access the fileUrls and thumbnailUrls
    const fileUrls = message.fileUrl || [];
    const thumbnailUrls = message.fileUrl || [];

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1, p: 1, borderRadius: '10px', bgcolor: 'grey.100' }}>
            {/* Delete Button */}
            <Button
                onClick={handleDeleteMessage}
                variant="outlined"
                color="error"
                sx={{ mt: 1, alignSelf: 'flex-end' }}
            >
                X
            </Button>

            <Typography variant="body1">{message.content || ''}</Typography>

            {/* Display each file with a thumbnail and preview option */}
            {fileUrls.length > 0 && (
                <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 1, alignItems: 'flex-end' }}>
                    {fileUrls.map((file, index) => (
                        <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}>
                            <Box
                                sx={{
                                    width: '100%',
                                    height: 300,
                                    overflow: 'hidden',
                                    borderRadius: '10px',
                                    '@media (max-width: 600px)': {
                                        height: '100%',
                                        width: '100%',
                                    },
                                }}
                            >
                                <img
                                    src={thumbnailUrls[index]}  // Only use valid thumbnailUrl, no placeholder
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => handleOpenPreview(file, index)}  // Click to preview the file
                                />
                            </Box>
                        </Box>
                    ))}
                </Box>
            )}

            <TableContainer component={Paper} 
                sx={{
                    marginTop: 0,
                    boxShadow: 'none',
                    backgroundColor: 'transparent',
                    outline: 'none',
                }}
            >
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{ fontSize: '0.45rem', textAlign: 'right', p: 0.5, color: 'grey.500' }}>
                                User: {senderEmail || 'Unknown Email'}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ fontSize: '0.45rem', textAlign: 'right', p: 0.5, color: 'grey.500' }}>
                                {new Date(message.timestamp).toLocaleString() || 'N/A'}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Modal for Preview */}
            <Dialog
                open={openPreview}
                onClose={handleClosePreview}
                maxWidth={false}
                fullWidth
                sx={{
                    '& .MuiDialog-paper': {
                        margin: 0,
                        padding: 0,
                        width: '100%',
                        height: '100%',
                        maxWidth: '100vw',
                        maxHeight: '100vh',
                        backgroundColor: 'black',  // Black background for the preview modal
                        '@media (max-width: 600px)': {
                            width: '100%',
                            height: '80vh',
                            borderRadius: 0,
                        },
                    },
                }}
                onTouchStart={handleTouchStart}  // Add touch start event for swipe gesture
                onTouchMove={handleTouchMove}    // Add touch move event for swipe gesture
                onTouchEnd={handleTouchEnd}      // Add touch end event for swipe gesture
            >
                <DialogTitle sx={{ display: 'none' }}></DialogTitle>
                <DialogContent sx={{ p: 0, margin: 0, width: '100vw', height: '100vh' }}>
                    <img
                        src={previewFile}  // Only use the previewFile, no placeholder
                        alt="Preview"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                        }}
                    />
                </DialogContent>
                <DialogActions sx={{ position: 'absolute', bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.7)', padding: 2 }}>
                    {/* Back Arrow */}
                    <Button onClick={handlePrevImage} color="primary" sx={{ color: 'white' }}>
                        &#8249; {/* Left Arrow Symbol */}
                    </Button>
                    <Button onClick={handleClosePreview} color="primary" sx={{ color: 'white' }}>
                        Close
                    </Button>
                    {/* Next Arrow */}
                    <Button onClick={handleNextImage} color="secondary" sx={{ color: 'white' }}>
                        &#8250; {/* Right Arrow Symbol */}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ChatMessage;
