import React from 'react';
import { Box } from '@mui/material';
import UserChannels from './Chat/UserChannels'; // Adjust path to your correct location of UserChannels

const Sidebar = ({ sidebarOpen, toggleSidebar, onChannelSelect }) => {
  return (
    <Box
      sx={{
        display: sidebarOpen ? 'block' : 'none', // Show or hide sidebar based on sidebarOpen state
        width: sidebarOpen ? '20%' : '0%', // Adjust width based on sidebarOpen
        height: '100vh',
        bgcolor: 'grey.300',
        position: 'sticky',
        top: 70,
        p: 1,  // Padding
        m: 1,  // Margin
        transition: 'width 0.3s ease', // Smooth transition for opening/closing the sidebar
      }}
    >
      {/* Load the UserChannels component inside Sidebar */}
      <UserChannels onChannelSelect={onChannelSelect} />
    </Box>
  );
};

export default Sidebar;
