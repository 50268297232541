import React, { useState, useEffect } from 'react';
import {
  Box, List, ListItem, ListItemText, CircularProgress, Typography,
  Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import Cookies from 'js-cookie'; // Make sure to import js-cookie to access cookies
import { useAuth } from '../../contexts/AuthContext';  // Ensure AuthContext is set up
import { useAxios } from '../../contexts/AuthContext'; // Using axios from AuthContext

const UserChannels = ({ onChannelSelect }) => {
  const { user, loading: authLoading, error: authError } = useAuth();  // Get authenticated user from context
  const axiosInstance = useAxios();  // Get the axios instance from AuthContext
  const [channels, setChannels] = useState([]);  // State for storing channels
  const [loading, setLoading] = useState(false);  // State for loading channels
  const [channelFiles, setChannelFiles] = useState([]);  // State for storing files from Storj
  const [filesLoading, setFilesLoading] = useState(false);  // State for loading files
  const [error, setError] = useState(null);  // State for error handling
  const [openModal, setOpenModal] = useState(false);  // State for modal visibility
  const [newChannelName, setNewChannelName] = useState('');  // State for new channel name
  const [newChannelDescription, setNewChannelDescription] = useState('');  // State for new channel description

  // Check authentication status and fetch channels
  useEffect(() => {
    console.log('Auth Loading:', authLoading);  // Debug: log authLoading status
    console.log('User:', user);  // Debug: log the user object

    if (authLoading) {
      console.log('Authentication in progress, skipping channel fetch.');
      return;  // Skip fetch if auth is still loading
    }

    if (authError) {
      console.error('Authentication Error:', authError);
      setError('Authentication failed. Please log in again.');
      return;
    }

    if (!user?.userId) {  // Changed from _id to userId based on your backend response
      console.error('User not authenticated or missing user ID.');
      setError('User not authenticated. Please log in to continue.');
      return;
    }

    // Log the token check
    const token = Cookies.get('accessToken') || localStorage.getItem('accessToken');
    if (!token) {
      console.error('No access token found in cookies or localStorage.');
      setError('No token found. Please log in to continue.');
      return;
    }

    console.log('Fetching channels for user:', user.userId);  // Use user.userId here
    setLoading(true);
    axiosInstance
      .get(`/api/channels/${user.userId}`)  // Adjusted based on userId
      .then((response) => {
        setChannels(response.data.channels);
      })
      .catch((err) => {
        console.error('Error fetching channels:', err);
        setError('Failed to fetch channels. Please try again.');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user, authLoading, authError, axiosInstance]);

  // Fetch files for the selected channel
  const fetchChannelFiles = async (channelId) => {
    console.log('Fetching files for channel:', channelId);  // Debugging: log the channel ID
    setFilesLoading(true);
    try {
      const response = await axiosInstance.get(`/api/storj/files/${channelId}`);
      setChannelFiles(response.data.files);
    } catch (error) {
      console.error('Error fetching channel files:', error);
      setError('Failed to fetch files for the channel.');
    } finally {
      setFilesLoading(false);
    }
  };

  // Handle channel selection
  const handleChannelSelect = (channel) => {
    fetchChannelFiles(channel._id);  // Assuming channel._id is the channel identifier
    onChannelSelect(channel);
  };

  // Handle channel creation
  const handleCreateChannel = async () => {
    if (!newChannelName) {
      setError('Channel name is required.');
      return;
    }

    const newChannel = {
      name: newChannelName,
      description: newChannelDescription || '',
      creator_id: user.userId,  // Use user.userId here instead of _id
    };

    try {
      const response = await axiosInstance.post(`/api/channels/${user.userId}`, newChannel);  // Adjusted user.userId
      if (response.status === 201) {
        setChannels((prevChannels) => [...prevChannels, response.data.channel]);
        setNewChannelName('');
        setNewChannelDescription('');
        setOpenModal(false);
      } else {
        console.error('Unexpected response status:', response.status);
        setError('Unexpected error occurred while creating channel.');
      }
    } catch (error) {
      console.error('Create Channel Error:', error);
      setError(error.response?.data?.message || 'Failed to create channel.');
    }
  };

  // Render loading spinner, error message, or the channels list
  if (authLoading || loading) return <CircularProgress />;  // Show loading spinner if authentication/loading is in progress
  if (error) return <Typography color="error">{error}</Typography>;  // Show error message if there's an error

  return (
    <Box>
      {/* Button to open modal */}
      <Button variant="contained" color="primary" onClick={() => setOpenModal(true)}>
        Create Channel
      </Button>

      {/* List of Channels */}
      {channels.length > 0 ? (
        <List>
          {channels.map((channel) => (
            <ListItem key={channel._id} onClick={() => handleChannelSelect(channel)}>
              <ListItemText primary={channel.name} />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="body2">No channels found for this user.</Typography>
      )}

      {/* Modal for Channel Creation */}
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>Create a New Channel</DialogTitle>
        <DialogContent>
          <TextField
            label="Channel Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={newChannelName}
            onChange={(e) => setNewChannelName(e.target.value)}
          />
          <TextField
            label="Description (optional)"
            variant="outlined"
            fullWidth
            margin="normal"
            value={newChannelDescription}
            onChange={(e) => setNewChannelDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleCreateChannel} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {/* Display files for selected channel */}
      {channelFiles.length > 0 && (
        <Box mt={3}>
          <Typography variant="h6">Files in {channelFiles[0]?.channelName || 'this channel'}</Typography>
          {filesLoading ? (
            <CircularProgress />
          ) : (
            <List>
              {channelFiles.map((file, index) => (
                <ListItem key={index}>
                  <ListItemText primary={file.name} secondary={`Size: ${file.size} bytes`} />
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      )}
    </Box>
  );
};

export default UserChannels;
