import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate here
import { useAuth } from '../contexts/AuthContext'; // Use useAuth to get login function
import { Box, Button, TextField, Typography, Link } from '@mui/material'; // Added Link for routing

const Login = () => {
  const navigate = useNavigate(); // useNavigate used here inside Router context
  const { login, error } = useAuth(); // Get login function and error state from AuthContext
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await login({ email, password }); // Use the login function from AuthContext

      // After successful login, redirect to the desired route (dashboard or user channels)
      navigate('/'); // You can change this to the route where you want to navigate after login
    } catch (error) {
      // This block should no longer be needed since error is set in AuthContext
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', padding: 2 }}>
      <Box sx={{ maxWidth: 400, width: '100%' }}>
        <Typography variant="h4" gutterBottom align="center" sx={{ mb: 2 }}>
          Login
        </Typography>

        {error && <Typography color="error" variant="body2" align="center" sx={{ mb: 2 }}>{error}</Typography>}

        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            type="email"
            label="Email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            sx={{ mb: 2 }}
            variant="outlined"
          />
          <TextField
            fullWidth
            type="password"
            label="Password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            sx={{ mb: 2 }}
            variant="outlined"
          />
          <Button
            variant="contained"
            fullWidth
            type="submit"
            disabled={loading}
            sx={{ mb: 2 }}
          >
            {loading ? 'Logging in...' : 'Login'}
          </Button>
        </form>

        {/* Additional Links for Registration and Password Reset */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Link
            component="button"
            variant="body2"
            onClick={() => navigate('/register')} // Navigate to registration page
            sx={{ textDecoration: 'none' }}
          >
            Don't have an account? Register
          </Link>

          <Link
            component="button"
            variant="body2"
            onClick={() => navigate('/reset-password')} // Navigate to reset password page
            sx={{ textDecoration: 'none' }}
          >
            Forgot Password?
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
