import React, { useState, useEffect, createContext, useContext } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

// Create Axios instance
const axiosInstance = axios.create({
  baseURL: 'http://localhost:5000', // Replace with your API URL
  headers: {
    'Content-Type': 'application/json',
  },
});

// Helper function to get token from Cookies or localStorage
const getToken = () => {
  const token = Cookies.get('accessToken') || localStorage.getItem('accessToken');
  return token;
};

// Add request interceptor to include token in headers
axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle token expiration (401)
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      Cookies.remove('accessToken');
      localStorage.removeItem('accessToken');
      window.location.reload(); // Optionally reload to trigger re-login
    }
    return Promise.reject(error);
  }
);

// Create AuthContext
const AuthContext = createContext();

// AuthProvider Component
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch the current user using the token
  const fetchCurrentUser = async () => {
    const token = getToken();
    
    if (!token) {
        setError('No token found. Please log in.');
        setLoading(false);  // Finish loading state
        return;  // Exit early if no token
    }

    try {
        const response = await axiosInstance.get('/api/users/me');
        setUser(response.data); // Store user data
    } catch (err) {
        setError('Failed to fetch user.');
    } finally {
        setLoading(false); // Finish loading state after the API call
    }
};


  // Log in function
  const login = async (credentials) => {
    try {
        const { data } = await axiosInstance.post('/api/users/login', credentials);

        // Set token in Cookies and localStorage
        Cookies.set('accessToken', data.token, { expires: 1 / 24 }); // Token expires in 1 hour
        localStorage.setItem('accessToken', data.token); // Store token in localStorage

        // Now fetch the user data after saving the token
        await fetchCurrentUser();
    } catch (err) {
        setError('Login failed. Please try again.');
    }
};

  // Log out function
  const logout = () => {
    setUser(null);
    Cookies.remove('accessToken');
    localStorage.removeItem('accessToken');
  };

  // Register function
  const register = async (credentials) => {
    try {
      const { data } = await axiosInstance.post('/api/users/register', credentials);
      setUser(data.user); // Set user data immediately after registration
    } catch (err) {
      setError(err.response?.data?.message || 'Registration failed.');
    }
  };

  // Validate token and fetch user on app load
  useEffect(() => {
    fetchCurrentUser();
  }, []); // Empty dependency array to run this effect only once on mount

  return (
    <AuthContext.Provider value={{ user, loading, error, login, register, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to access AuthContext
export const useAuth = () => useContext(AuthContext);

// Custom hook to use axiosInstance in components
export const useAxios = () => axiosInstance;
