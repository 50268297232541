import React, { useState } from 'react';
import { Box, TextField, IconButton, CircularProgress, InputAdornment, Typography, Card, CardContent, useMediaQuery, useTheme } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import axiosInstance from '../../API/axiosInstance';

const ChatSender = ({ channel, setMessages, fetchMessages }) => {
    const [newMessage, setNewMessage] = useState('');
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [uploadCompleteMessage, setUploadCompleteMessage] = useState(false);
    const [fileProgress, setFileProgress] = useState({});

    // Media query hook to detect if the screen size is mobile
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // 'sm' corresponds to the default breakpoint for mobile screens

    const handleSendMessage = async (e) => {
        e.preventDefault();

        try {
            const token = localStorage.getItem('accessToken');
            if (!token) throw new Error('Token not available.');

            if (!newMessage.trim() && files.length === 0) {
                throw new Error('Please enter a message or attach a file.');
            }

            setUploading(true);

            const formData = new FormData();
            formData.append('text', newMessage.trim());

            files.forEach((file) => {
                formData.append('files', file);
            });

            // Make the POST request to send the message
            const response = await axiosInstance.post(
                `/api/message/channel/${channel._id}/send`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        const percent = Math.round((loaded * 100) / total);

                        // Update progress for each file by using the file's name or index as the key
                        setFileProgress((prevProgress) => {
                            const updatedProgress = { ...prevProgress };

                            // Loop through each file and set the progress for each file based on index or name
                            files.forEach((file, index) => {
                                updatedProgress[file.name] = percent;
                            });

                            return updatedProgress;
                        });
                    },
                }
            );

            // Re-fetch messages to include the newly sent message
            fetchMessages();

            setNewMessage('');
            setFiles([]);
            setUploadCompleteMessage('Message sent!');

            setTimeout(() => {
                setUploadCompleteMessage(false);
            }, 3000);
        } catch (error) {
            console.error(error);
            setUploadCompleteMessage(error.message);
        } finally {
            setUploading(false);
        }
    };

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    };

    const handleRemoveFile = (fileToRemove) => {
        setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
        setFileProgress((prevProgress) => {
            const updatedProgress = { ...prevProgress };
            delete updatedProgress[fileToRemove.name]; // Remove progress for the removed file
            return updatedProgress;
        });
    };

    const handleMessageChange = (e) => {
        setNewMessage(e.target.value);
    };

    const isImageFile = (file) => file && file.type.startsWith('image/');

    return (
        <Box
            component="form"
            onSubmit={handleSendMessage}
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                justifyContent: 'center',
                width: '100%',
                marginTop: '10px',
                flexDirection: 'column',
                position: 'relative',
            }}
        >
            {/* Floating card to display attached files */}
            {files.length > 0 && (
                <Card
                    sx={{
                        width: '100%',
                        maxHeight: '150px',
                        position: 'absolute',
                        top: isMobile ? '-150px' : '-80px',
                        left: 0,
                        padding: 1,
                        backgroundColor: 'rgba(0, 0, 0, 0.9)',
                        borderRadius: 2,
                        boxShadow: 3,
                        overflowY: 'auto',
                    }}
                >
                    <CardContent sx={{ padding: '8px !important' }}>
                        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', marginBottom: 1 }}>
                            {files.map((file, index) => (
                                <Box key={index} sx={{ position: 'relative', display: 'flex', alignItems: 'center', gap: 1 }}>
                                    {isImageFile(file) ? (
                                        <img
                                            src={URL.createObjectURL(file)}
                                            alt={file.name}
                                            style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '5px' }}
                                        />
                                    ) : (
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                textOverflow: 'ellipsis',
                                                maxWidth: '150px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            {file.name}
                                        </Typography>
                                    )}

                                    {/* Show progress for each file */}
                                    {fileProgress[file.name] && (
                                        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center',color:'white' }}>
                                            <CircularProgress
                                                variant="determinate"
                                                value={fileProgress[file.name]}
                                                size={20}
                                                sx={{ marginLeft: 1 }}
                                            />
                                            <Typography variant="body2" sx={{ marginLeft: 1 }}>
                                                {fileProgress[file.name]}%
                                            </Typography>
                                        </Box>
                                    )}

                                    <IconButton onClick={() => handleRemoveFile(file)} sx={{ color: 'red' }}>
                                        X
                                    </IconButton>
                                </Box>
                            ))}
                        </Box>
                    </CardContent>
                </Card>
            )}

            {/* Textfield for message input */}
            <TextField
                variant="outlined"
                value={newMessage}
                onChange={handleMessageChange}
                fullWidth
                sx={{ flexGrow: 1, marginTop: '20px' }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <IconButton component="label" sx={{ p: 0 }}>
                                <AttachFileIcon />
                                <input type="file" hidden multiple onChange={handleFileChange} />
                            </IconButton>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                type="submit"
                                color="secondary"
                                sx={{ width: '40px', height: '40px', padding: 0 }}
                                disabled={uploading || (!newMessage.trim() && files.length === 0)}
                            >
                                {uploading ? <CircularProgress size={24} /> : <SendIcon />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />

            {/* Display message sent confirmation */}
            {uploadCompleteMessage && (
                <Box sx={{ position: 'fixed', bottom: 16, left: 16, bgcolor: 'error.main', p: 2, borderRadius: 10, boxShadow: 3 }}>
                    <span style={{ color: 'white' }}>{uploadCompleteMessage}</span>
                </Box>
            )}
        </Box>
    );
};

export default ChatSender;
