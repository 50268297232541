import React, { useState } from 'react';
import { Box, Container, Typography, useMediaQuery, CircularProgress } from '@mui/material';
import Sidebar from './Sidebar'; 
import Navigation from './Navigation';
import Chat from '../components/Chat/Chat';

const MOBILE_HEIGHT = '97vh';
const DESKTOP_HEIGHT = '87vh';

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [channelFiles, setChannelFiles] = useState([]);
  
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const isMobile = useMediaQuery('(max-width: 600px)');

  const toggleSidebar = () => {
    setSidebarOpen((prevState) => !prevState);
  };

  const handleChannelSelect = async (channel) => {
    setLoading(true);
    setError(null);
    setConfirmation(null);

    try {
      // Fetch files and folders concurrently
      const [filesResponse, foldersResponse] = await Promise.all([
        fetchFiles(channel._id),
        fetchFolders(channel._id),
      ]);

      console.log('Files Response:', filesResponse);
      console.log('Folders Response:', foldersResponse);

      if (filesResponse.success && foldersResponse.success) {
        setSelectedChannel({ ...channel, files: filesResponse.files, folders: foldersResponse.folders });
        setChannelFiles(filesResponse.files); // Only set files in channelFiles
        setConfirmation("Channel contents loaded successfully!");
      } else {
        throw new Error('Failed to load channel contents');
      }
    } catch (err) {
      setError(`Error: ${err.message || 'Something went wrong while loading the channel.'}`);
    } finally {
      setLoading(false);
    }
  };

  const fetchFiles = async (channelId) => {
    const authToken = localStorage.getItem('authToken') || Cookies.get('authToken'); // Or use your AuthContext if applicable
    
    try {
      const response = await fetch(`/api/files/${channelId}`, {
        headers: {
          'Authorization': `Bearer ${authToken}`, // Ensure the token is correct
        },
      });
  
      // Check if response is OK (status 200) and if the content-type is JSON
      if (response.ok && response.headers.get('Content-Type')?.includes('application/json')) {
        const data = await response.json();
        return { success: true, files: data.files };
      } else {
        // If the response is not OK or not JSON, handle accordingly
        const text = await response.text();
        console.error('Error fetching files:', text);
        return { success: false, error: 'Failed to fetch files. ' + text };
      }
    } catch (error) {
      console.error('Error fetching files:', error);
      return { success: false, error: error.message || 'Failed to fetch files' };
    }
  };
  
  const fetchFolders = async (channelId) => {
    try {
      const response = await fetch(`/api/folders/${channelId}`);
  
      // Check if response is OK (status 200) and if the content-type is JSON
      if (response.ok && response.headers.get('Content-Type')?.includes('application/json')) {
        const data = await response.json();
        return { success: true, folders: data.folders };
      } else {
        // If the response is not OK or not JSON, handle accordingly
        const text = await response.text();
        console.error('Error fetching folders:', text);
        return { success: false, error: 'Failed to fetch folders. ' + text };
      }
    } catch (error) {
      console.error('Error fetching folders:', error);
      return { success: false, error: error.message || 'Failed to fetch folders' };
    }
  };
  

  return (
    <Box sx={{ display: 'flex', width: '100%', pt: 8, height: isMobile ? MOBILE_HEIGHT : DESKTOP_HEIGHT }}>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} onChannelSelect={handleChannelSelect} />
      <Box sx={{ width: sidebarOpen ? '80%' : '100%', flex: 1, display: 'flex', flexDirection: 'column', position: 'sticky', top: 70, bgcolor: 'grey.100', p: 1, m: 1 }}>
        <Navigation toggleSidebar={toggleSidebar} />
        <Container>
          {loading && (
            <Box sx={{ textAlign: 'center' }}>
              <CircularProgress />
              <Typography>Loading channel contents...</Typography>
            </Box>
          )}

          {error && (
            <Box sx={{ textAlign: 'center' }}>
              <Typography color="error">{error}</Typography>
            </Box>
          )}

          {confirmation && (
            <Box sx={{ textAlign: 'center' }}>
              <Typography color="success.main">{confirmation}</Typography>
            </Box>
          )}

          {selectedChannel ? (
            <Box sx={{ width: '100%', bgcolor: 'yellow', p: 1, m: 1 }}>
              <Chat channel={selectedChannel} files={channelFiles} />
            </Box>
          ) : (
            <Typography variant="h6" align="center">
              Please select a channel
            </Typography>
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default Dashboard;
