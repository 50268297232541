import React, { useState } from 'react';
import { Box, Typography, CircularProgress, Stack, Button } from '@mui/material';
import MediaChannels from '../components/Chat/MediaChannels'; // Assuming this is another component

const DynamicMediaPage = () => {
  const [selectedMedia, setSelectedMedia] = useState(null);  // Track selected media
  const [loading, setLoading] = useState(false);  // Track loading state
  const [filesInChannel, setFilesInChannel] = useState([]);  // Track files for the selected channel
  const [channelName, setChannelName] = useState(null);  // Track the current selected channel

  // Mock data: Channels with files
  const channelsData = {
    'Default Channel': [
      { objectKey: 'image1.png' },
      { objectKey: 'document1.pdf' },
    ],
    'new channel': [
      { objectKey: 'video1.mp4' },
      { objectKey: 'image2.png' },
    ],
    'asdfsadfsd': [
      { objectKey: 'document2.pdf' },
      { objectKey: 'image3.jpg' },
    ],
    'sub channel': [
      { objectKey: 'image4.png' },
      { objectKey: 'document3.pdf' },
    ],
    'qqqqqqqqqqqqqqqq': [
      { objectKey: 'video2.mp4' },
      { objectKey: 'image5.png' },
    ],
  };

  // Handle media click (when a user selects a file from the channel)
  const handleMediaClick = (mediaName) => {
    setLoading(true);
    setSelectedMedia(mediaName);  // Set selected media (this could be a file name)
    setLoading(false);
  };

  // Render selected media (image, video, PDF)
  const renderSelectedMedia = () => {
    if (!selectedMedia) return null;

    // Sample logic based on file extensions, this can be extended to be more dynamic
    const fileExtension = selectedMedia.split('.').pop();

    switch (fileExtension) {
      case 'png':
      case 'jpg':
      case 'jpeg':
        return <img src={selectedMedia} alt="Selected Media" style={{ maxWidth: '100%', maxHeight: '80vh', objectFit: 'contain', borderRadius: 8 }} />;
      case 'mp4':
        return <video width="100%" controls><source src={selectedMedia} type="video/mp4" />Your browser does not support the video tag.</video>;
      case 'pdf':
        return (
          <Box sx={{ width: '100%', height: '80vh', overflow: 'auto' }}>
            <iframe src={selectedMedia} width="100%" height="100%" style={{ border: 'none' }} title="PDF Viewer" />
          </Box>
        );
      default:
        return <Typography variant="h6" color="error">Unsupported media type</Typography>;
    }
  };

  // Handle channel selection and fetch the files for that channel
  const handleChannelSelect = (channelName) => {
    setLoading(true);
    setChannelName(channelName);  // Set the current selected channel name

    // Get the files for the selected channel
    const files = channelsData[channelName] || [];
    setFilesInChannel(files);  // Set the files for the selected channel
    setLoading(false);
  };

  return (
    <Box sx={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', bgcolor: 'background.default', p: 2 }}>
      {/* Sidebar with Channels */}
      <Box sx={{ width: 150, bgcolor: 'background.paper', p: 2, boxShadow: 2 }}>
        <Typography variant="h6" gutterBottom>Channels</Typography>
        <Stack direction="column" spacing={2} alignItems="flex-start">
          {Object.keys(channelsData).map((channel, index) => (
            <Button
              key={index}
              variant="outlined"
              onClick={() => handleChannelSelect(channel)}  // Handle channel selection
              sx={{ width: '100%' }}
            >
              {channel}
            </Button>
          ))}
        </Stack>
      </Box>

      {/* Main Content */}
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Select a Channel to View Files
        </Typography>

        {/* Show loading indicator */}
        {loading && <CircularProgress />}

        {/* Show selected channel's files */}
        <Box sx={{ mt: 4 }}>
          {channelName && filesInChannel.length > 0 ? (
            <Typography variant="h5">{`Files in ${channelName}`}</Typography>
          ) : (
            !loading && (
              <Typography variant="h6" align="center">
                Please select a channel to view files.
              </Typography>
            )
          )}

          {filesInChannel.length > 0 && (
            <Stack direction="column" spacing={2} alignItems="center">
              {filesInChannel.map((file, index) => (
                <Button
                  key={index}
                  variant="outlined"
                  onClick={() => handleMediaClick(file.objectKey)}  // Handle file selection
                  sx={{ margin: '5px' }}
                >
                  {file.objectKey}
                </Button>
              ))}
            </Stack>
          )}
        </Box>

        {/* Display the selected media */}
        {selectedMedia && (
          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <Typography variant="h5">Selected Media</Typography>
            <Box sx={{ mt: 2 }}>
              {renderSelectedMedia()}  {/* Render the selected media */}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DynamicMediaPage;
