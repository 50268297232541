import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Button, useMediaQuery, Drawer, Box } from '@mui/material';
import MenuOpen from '@mui/icons-material/MenuOpen';  // Sidebar toggle icon
import CloseIcon from '@mui/icons-material/Close';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import MenuIconImage from '../images/logo.png';  // Custom logo image for mobile menu

const Navigation = ({ sidebarOpen, toggleSidebar }) => {
  const { user, logout } = useAuth();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleLogout = () => {
    logout();
  };

  const toggleMobileNav = () => setMobileNavOpen((prev) => !prev);

  return (
    <AppBar position="fixed" sx={{ backgroundColor: 'white', borderBottomLeftRadius: '30px', borderBottomRightRadius: '30px' }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        {/* Sidebar Toggle Button for desktop */}
        <IconButton edge="start" color="inherit" onClick={toggleSidebar} aria-label="menu" sx={{ mr: 2, color: 'grey.300' }}>
          <MenuOpen sx={{ fontSize: '2rem' }} />
        </IconButton>

        {/* Branding */}
        <Typography variant="h6" sx={{ flexGrow: 1, cursor: 'pointer' }}>
          <NavLink to="/" style={{ textDecoration: 'none', color: 'inherit' }}>VAU7T</NavLink>
        </Typography>

        {/* Mobile Drawer Toggle Button */}
        {isMobile && (
          <IconButton edge="start" color="inherit" onClick={toggleMobileNav} aria-label="menu" sx={{ ml: 'auto', mr: 3 }}>
            <img src={MenuIconImage} alt="Menu" style={{ width: '30px', height: '30px' }} />
          </IconButton>
        )}

        {/* Desktop Navigation Links */}
        {!isMobile && (
          <Box sx={{ marginRight: '50px' }}>
            <NavLink to="/user-channels" style={{ marginRight: '20px', textDecoration: 'none', color: 'black' }}>
              /user-channels
            </NavLink>
            <NavLink to="/dashboard" style={{ marginRight: '20px', textDecoration: 'none', color: 'black' }}>
              Dashboard
            </NavLink>
            {user ? (
              <Button color="inherit" onClick={handleLogout}>Logout</Button>
            ) : (
              <Button color="inherit" component={NavLink} to="/login">Login</Button>
            )}
          </Box>
        )}

        {/* Mobile Drawer */}
        <Drawer anchor="right" open={isMobileNavOpen} onClose={toggleMobileNav}>
          <Box sx={{ width: 250, display: 'flex', flexDirection: 'column', height: '100%', padding: 2 }}>
            <IconButton onClick={toggleMobileNav} sx={{ alignSelf: 'flex-end', mb: 2 }}>
              <CloseIcon />
            </IconButton>

            <NavLink to="/dashboard" style={{ textDecoration: 'none', color: 'black', marginBottom: '10px' }} onClick={toggleMobileNav}>
              Dashboard
            </NavLink>

            {user ? (
              <Button color="inherit" onClick={handleLogout} sx={{ textAlign: 'left', justifyContent: 'flex-start' }}>
                Logout
              </Button>
            ) : (
              <Button color="inherit" component={NavLink} to="/login" sx={{ textAlign: 'left', justifyContent: 'flex-start' }}>
                Login
              </Button>
            )}
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Navigation;
